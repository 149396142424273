import React from 'react'
import {
	FontAwesomeIcon
} from '@fortawesome/react-fontawesome'
import {
	faSpinner
} from '@fortawesome/free-solid-svg-icons'

const Loader = () => (
	<div className="d-flex justify-content-center py-3">
		<FontAwesomeIcon icon={faSpinner} spin size="2x" />
	</div>
)

export default Loader
