import React, {
	useState
} from 'react'
import {
	Container,
	Row,
	Col,
	Form,
	Button
} from 'react-bootstrap'
import {
	FontAwesomeIcon
} from '@fortawesome/react-fontawesome'
import {
	faSignInAlt
} from '@fortawesome/free-solid-svg-icons'
import validate from 'validate.js'
import axios from 'axios'
import {
	DEFAULT_ERROR
} from '../../utils'
import {
	Redirect
} from 'react-router'
import {
	useDispatch,
	useSelector
} from 'react-redux'
import {
	setUserValue
} from '../../store/actions/user'
import Errorlist from '../../components/ErrorList'

const Login = () => {

	const dispatch = useDispatch()
	const auth = useSelector(state => state.user.auth)
	const user = useSelector(state => state.user.user)

	const [inputData, setInputData] = useState({
		username: '',
		password: ''
	})
	const [disableButton, setDisableButton] = useState(false)
	const [errors, setErrors] = useState([])
	const [redirectToDashboard, setRedirectToDashboard] = useState(false)

	const constraints = {
		username: {
			presence: {
				allowEmpty: false,
				message: 'Korisničko ime je obavezno.'
			}
		},
		password: {
			presence: {
				allowEmpty: false,
				message: 'Šifra je obavezna.'
			}
		}
	}

	const setInputValue = (name, value) => {
		setInputData({
			...inputData,
			[name]: value
		})
	}

	const login = () => {
		let messages = validate(
			inputData,
			constraints,
			{
				format: 'flat',
				fullMessages: false
			}
		)
		if (messages) {
			setErrors(messages)
		} else {
			setErrors([])
			setDisableButton(true)
			axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, { ...inputData })
				.then(response => {
					localStorage.setItem('auth', JSON.stringify(response.data))
					dispatch(setUserValue('auth', response.data))
						.then(() => {
							axios.post(`${process.env.REACT_APP_API_URL}/api/auth/me`, {}, {
								headers: {
									Authorization: `Bearer ${response.data.access_token}`
								}
							})
								.then(response => {
									dispatch(setUserValue('user', response.data))
										.then(() => {
											setDisableButton(false)
											setRedirectToDashboard(true)
										})
										.catch(error => {

										})
								})
								.catch(error => {

								})
						})
				})
				.catch(error => {
					console.log(error.response)
					setDisableButton(false)
					try {
						if (error.response.status === 401) {
							setErrors(['Korisničko ime i/ili šifra su pogrešni.'])
						}
					} catch (error) {
						setErrors([DEFAULT_ERROR])
					}
				})
		}
	}

	const checkRedirectToDashboard = () => {
		if (redirectToDashboard || (auth && user)) {
			return true
		}
		return false
	}

	return (
		<React.Fragment>
			{
				checkRedirectToDashboard() &&
					<Redirect to="/user" />
			}
			<Container className="pt-5">
				<Row className="d-flex justify-content-center">
					<Col xl={4}>
						<h1 className="text-center">Prijava</h1>
						<Form>
							<Errorlist errors={errors} />
							<Form.Group controlId="formBasicEmail">
								<Form.Label>Korisničko ime</Form.Label>
								<Form.Control
									type="text"
									name="username"
									value={inputData.username}
									onChange={
										evt => setInputValue(evt.target.name, evt.target.value)
									}
								/>
							</Form.Group>
							<Form.Group controlId="formBasicPassword">
								<Form.Label>Šifra</Form.Label>
								<Form.Control
									type="password"
									name="password"
									value={inputData.password}
									onChange={
										evt => setInputValue(evt.target.name, evt.target.value)
									}
								/>
							</Form.Group>
							<Button
								variant="primary"
								type="button"
								disabled={disableButton}
								onClick={login}
							>
								<FontAwesomeIcon icon={faSignInAlt} /> Prijavi se
							</Button>
						</Form>
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	)
}

export default Login
