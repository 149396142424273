import React, {
	useEffect,
	useState
} from 'react'
import {
	Container,
	Table,
	Badge
} from 'react-bootstrap'
import {
	useSelector
} from 'react-redux'
import {
	Redirect
} from 'react-router'
import AdminTopMenu from '../../components/AdminTopMenu'
import axios from 'axios'
import Loader from '../../components/Loader'
import NoResultTableRow from '../../components/NoResultTableRow'
import _ from 'lodash'

const Dashboard = () => {

	const auth = useSelector(state => state.user.auth)

	const [redirectToHome, setRedirectToHome] = useState(false)
	const [loadingData, setLoadingData] = useState(false)
	const [data, setData] = useState([])

	useEffect(() => {
		if (!auth) {
			setRedirectToHome(true)
		}
	}, [redirectToHome])

	useEffect(() => {
		getUsers()
	}, [])

	const getUsers = () => {
		setLoadingData(true)
		axios.get(`${process.env.REACT_APP_API_URL}/api/message-accounts`, {
			headers: {
				Authorization: `Bearer ${auth.access_token}`
			}
		})
			.then(response => {
				console.log('getUsers', response.data)
				const d = _.orderBy(response.data, ['full_name'],['asc'])
				setLoadingData(false)
				setData(d)
			})
			.catch(error => {
				setLoadingData(false)
			})
	}

	return (
		<React.Fragment>
			{
				redirectToHome &&
					<Redirect to="/" />
			}
			<AdminTopMenu />
			<Container>
				<div className="py-3">
					<h2>Lista korisnika</h2>
				</div>
				{
					loadingData ?
						<Loader />
						:
						<Table striped bordered hover>
							<thead>
							<tr>
								<th style={{ width: 60 }} className="text-center">#</th>
								<th>Ime i prezime</th>
								<th>Email</th>
								<th>Broj telefona</th>
								<th className="text-center">Šifra kupca</th>
								<th className="text-center">Status</th>
							</tr>
							</thead>
							<tbody>
							{
								data.length > 0 ?
									<React.Fragment>
										{
											data.map((item, index) => (
												<tr key={index}>
													<td className="text-center">{index + 1}</td>
													<td>{item.full_name}</td>
													<td>{item.email}</td>
													<td>{item.phone_number}</td>
													<td className="text-center">{item.user_code}</td>
													<td className="text-center">
														{
															item.is_verified ?
																<Badge variant="success">Verifikovan</Badge>
																:
																<Badge variant="danger">Nije verifikovan</Badge>
														}
													</td>
												</tr>
											))
										}
									</React.Fragment>
									: (
										<NoResultTableRow colspan={4} />
									)
							}
							</tbody>
						</Table>
				}
			</Container>
		</React.Fragment>
	)
}

export default Dashboard
