import {
	SET_USER_VALUE
} from './actionTypes.js';

export const setUserValue = (name, value) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_USER_VALUE,
				payload: {
					name,
					value
				}
      });
      resolve();
    });
  }
};
