import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import UIReducer from './reducers/ui'
import UserReducer from './reducers/user'

const rootReducer = combineReducers({
  ui: UIReducer,
  user: UserReducer
})

let composeEnhancers = compose

const configureStore = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
}

export default configureStore
