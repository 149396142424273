import {
  SET_UI_VALUE
} from '../actions/actionTypes'

const initialState = {
  selectedLanguage: 'sr'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UI_VALUE:
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    default:
      return state
  }
};

export default reducer
