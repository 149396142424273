import React from 'react'
import {
	Alert
} from 'react-bootstrap'

const ErrorList = props => (
	<React.Fragment>
		{
			props.errors.length > 0 &&
			<Alert variant="danger">
				{
					props.errors.map((error, index) => (
						<div key={index}>
							<span>{error}</span><br />
						</div>
					))
				}
			</Alert>
		}
	</React.Fragment>
)

export default ErrorList
