import React, {
	useEffect,
	useState
} from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route
} from 'react-router-dom'
import {
	useDispatch,
	useSelector
} from 'react-redux'
import {
	setUserValue
} from './store/actions/user'
import {
	FontAwesomeIcon
} from '@fortawesome/react-fontawesome'
import {
	faSpinner
} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Messages from './pages/Messages'
import Notices from './pages/Notices'
import News from './pages/News'
import Galleries from './pages/Galleries'

const App = () => {

	const dispatch = useDispatch()
	const auth = useSelector(state => state.user.auth)
	const user = useSelector(state => state.user.user)

	const [authChecked, setAuthChecked] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		try {
			let storageAuth = localStorage.getItem('auth')
			if (storageAuth) {
				storageAuth = JSON.parse(storageAuth)
				dispatch(setUserValue('auth', storageAuth))
					.then(() => {
						if (!user) {
							axios.post(`${process.env.REACT_APP_API_URL}/api/auth/me`, {}, {
								headers: {
									Authorization: `Bearer ${storageAuth.access_token}`
								}
							})
								.then(response => {
									dispatch(setUserValue('user', response.data))
										.then(() => {
											setAuthChecked(true)
											setIsLoading(false)
										})
								})
								.catch(error => {

								})
						} else {
							setIsLoading(false)
							setAuthChecked(true)
						}
					})
			} else {
				setIsLoading(false)
				setAuthChecked(true)
			}
		} catch (error) {
			setIsLoading(false)
			setAuthChecked(true)
		}
	}, [])

	return (
		<div>
			{
				isLoading &&
				<div
					style={{
						top: 0,
						right: 0,
						bottom: 0,
						left: 0,
						zIndex: 99999999
					}}
					className="d-flex bg-white align-items-center justify-content-center position-fixed"
				>
					<FontAwesomeIcon icon={faSpinner} spin size="2x" />
				</div>
			}
			{
				authChecked &&
				<Router>
					<Switch>
						<Route exact path="/">
							<Login />
						</Route>
						<Route exact path="/user">
							<Dashboard />
						</Route>
						<Route exact path="/user/messages">
							<Messages />
						</Route>
						<Route exact path="/user/notices">
							<Notices />
						</Route>
						<Route exact path="/user/news">
							<News />
						</Route>
						<Route exact path="/user/galleries">
							<Galleries />
						</Route>
					</Switch>
				</Router>
			}
		</div>
	)
}

export default App
