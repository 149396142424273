import React, {
	useEffect,
	useState
} from 'react'
import {
	Container,
	Table,
	Button,
	Modal,
	Form,
	DropdownButton,
	Dropdown
} from 'react-bootstrap'
import {
	useSelector
} from 'react-redux'
import {
	Redirect
} from 'react-router'
import AdminTopMenu from '../../components/AdminTopMenu'
import Loader from '../../components/Loader'
import NoResultTableRow from '../../components/NoResultTableRow'
import {
	FontAwesomeIcon
} from '@fortawesome/react-fontawesome'
import {
	faPlus,
	faTimes,
	faSignInAlt,
	faTrash,
	faEdit
} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import {
	Editor
} from '@tinymce/tinymce-react'
import validate from 'validate.js'
import ErrorList from '../../components/ErrorList'
import moment from 'moment'
import 'moment/locale/sr'

const News = () => {

	const auth = useSelector(state => state.user.auth)

	const emptyInputData = {
		id: null,
		article_title: '',
		article_content: '',
		file_id: null
	}

	const [redirectToHome, setRedirectToHome] = useState(false)
	const [loadingData, setLoadingData] = useState(false)
	const [data, setData] = useState([])
	const [showManageDataModal, setShowManageDataModal] = useState(false)
	const [inputData, setInputData] = useState({ ...emptyInputData })
	const [disableButton, setDisableButton] = useState(false)
	const [errors, setErrors] = useState([])
	const [itemToDelete, setItemToDelete] = useState(null)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false)

	const constraints = {
		article_title: {
			presence: {
				allowEmpty: false,
				message: 'Naslov je obavezan.'
			}
		},
		article_content: {
			presence: {
				allowEmpty: false,
				message: 'Sadržaj je obavezan.'
			}
		}
	}

	useEffect(() => {
		if (!auth) {
			setRedirectToHome(true)
		}
		getArticles()
	}, [])

	const getArticles = () => {
		setLoadingData(true)
		axios.get(`${process.env.REACT_APP_API_URL}/api/articles`)
			.then(response => {
				setData(response.data)
				setLoadingData(false)
			})
			.catch(error => {
				setLoadingData(false)
			})
	}

	const handleManageDataModal = () => {
		let showModal = !showManageDataModal
		if (!showModal) {
			setInputData({ ...emptyInputData })
		}
		setShowManageDataModal(showModal)
	}

	const saveForm = () => {
		let messages = validate(
			inputData,
			constraints,
			{
				format: 'flat',
				fullMessages: false
			}
		)
		if (messages) {
			setErrors(messages)
		} else {
			setErrors([])
			setDisableButton(true)
			if (inputData.id) {
				// update existing
				axios.post(`${process.env.REACT_APP_API_URL}/api/articles/${inputData.id}`, inputData, {
					headers: {
						Authorization: `Bearer ${auth.access_token}`
					}
				})
					.then(response => {
						handleManageDataModal()
						setDisableButton(false)
						const newData = data.map(item => item.id === inputData.id ? response.data : item)
						setData([
							...newData
						])
					})
					.catch(error => {
						setDisableButton(false)
					})
			} else {
				// create new
				axios.post(`${process.env.REACT_APP_API_URL}/api/articles`, inputData, {
					headers: {
						Authorization: `Bearer ${auth.access_token}`
					}
				})
					.then(response => {
						handleManageDataModal()
						setDisableButton(false)
						setData([
							...data,
							response.data
						])
					})
					.catch(error => {
						setDisableButton(false)
					})
			}
		}
	}

	const setInputValue = (name, value) => {
		setInputData({
			...inputData,
			[name]: value
		})
	}

	const openEditModal = item => {
		setInputData({
			id: item.id,
			article_title: item.article_title,
			article_content: item.article_content,
			file_id: item.file_id
		})
		handleManageDataModal()
	}

	const openDeleteModal = item => {
		setItemToDelete(item)
		handleDeleteDataModal()
	}

	const handleDeleteDataModal = () => {
		const showModal = !showDeleteModal
		if (!showModal) {
			setItemToDelete(null)
		}
		setShowDeleteModal(showModal)
	}

	const deleteData = () => {
		setDeleteButtonDisabled(true)
		axios.delete(`${process.env.REACT_APP_API_URL}/api/articles/${itemToDelete.id}`, {
			headers: {
				Authorization: `Bearer ${auth.access_token}`
			}
		})
			.then(response => {
				setDeleteButtonDisabled(false)
				handleDeleteDataModal()
				const newData = data.filter(item => item.id !== itemToDelete.id)
				setData([ ...newData ])
			})
			.catch(error => {
				setDeleteButtonDisabled(false)
			})
	}

	return (
		<React.Fragment>
			{
				redirectToHome &&
				<Redirect to="/" />
			}
			<AdminTopMenu page="news" />
			<Container>
				<div className="py-3 d-flex">
					<h2 className="mr-3">Vesti</h2>
					<Button variant="primary" className="align-self-center" onClick={handleManageDataModal}>
						<FontAwesomeIcon icon={faPlus} /> Dodaj
					</Button>
				</div>
				{
					loadingData ?
						<Loader />
						: (
							<Table striped bordered hover>
								<thead>
								<tr>
									<th style={{ width: 60 }} className="text-center">#</th>
									<th style={{ width: 100 }} className="text-center">Slika</th>
									<th>Naslov</th>
									<th style={{ width: 200 }} className="text-center">Datum kreiranja</th>
									<th style={{ width: 150 }} className="text-center">Akcije</th>
								</tr>
								</thead>
								<tbody>
								{
									data.length  > 0 ?
										<React.Fragment>
											{
												data.map((item, index) => (
													<tr key={index}>
														<td className="align-middle text-center">{index + 1}</td>
														<td />
														<td className="align-middle">{item.article_title}</td>
														<td className="align-middle text-center">{moment(item.created_at).locale('sr').format('LL')}</td>
														<td>
															<DropdownButton id="dropdown-basic-button" title="Akcije">
																<Dropdown.Item
																	onClick={
																		() => openEditModal(item)
																	}
																>
																	<FontAwesomeIcon icon={faEdit} /> Izmeni
																</Dropdown.Item>
																<Dropdown.Item
																	onClick={
																		() => openDeleteModal(item)
																	}
																>
																	<FontAwesomeIcon icon={faTrash} /> Obriši
																</Dropdown.Item>
															</DropdownButton>
														</td>
													</tr>
												))
											}
										</React.Fragment>
										: (
											<NoResultTableRow colspan={5} />
										)
								}
								</tbody>
							</Table>
						)
				}
			</Container>
			<Modal show={showManageDataModal} onHide={handleManageDataModal} size="xl">
				<Modal.Header closeButton>
					<Modal.Title>Uređivanje vesti</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<ErrorList errors={errors} />
						<Form.Group controlId="title">
							<Form.Label>Naslov</Form.Label>
							<Form.Control
								type="text"
								name="article_title"
								value={inputData.article_title}
								onChange={
									evt => setInputValue(evt.target.name, evt.target.value)
								}
							/>
						</Form.Group>
						<Form.Group controlId="content">
							<Form.Label>Sadržaj</Form.Label>
							<Editor
								apiKey={process.env.REACT_APP_TINYMCE_KEY}
								initialValue={inputData.article_content}
								init={{
									height: 500,
									menubar: false,
									plugins: [
										'advlist autolink lists link image charmap print preview anchor',
										'searchreplace visualblocks code fullscreen',
										'insertdatetime media table paste code help wordcount'
									],
									toolbar:
										'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link | addImageButton | help',
									setup: editor => {
										editor.ui.registry.addButton('addImageButton', {
											icon: 'image',
											tooltip: '',
											disabled: false,
											onAction: () => {
												/*this.editor = editor;
												this.toggleAddImageModal();*/
											},
											onSetup: buttonApi => {

											}
										});
									},
								}}
								onChange={
									evt => setInputValue('article_content', evt.target.getContent())
								}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleManageDataModal}>
						<FontAwesomeIcon icon={faTimes} /> Zatvori
					</Button>
					<Button variant="primary" onClick={saveForm} disabled={disableButton}>
						<FontAwesomeIcon icon={faSignInAlt} /> Snimi
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showDeleteModal} onHide={handleDeleteDataModal}>
				<Modal.Header closeButton>
					<Modal.Title>Obriši vest?</Modal.Title>
				</Modal.Header>
				<Modal.Body>Da li ste sigurni da želite da obrišete vest?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleDeleteDataModal}>
						<FontAwesomeIcon icon={faTimes} /> Zatvori
					</Button>
					<Button variant="danger" onClick={deleteData} disabled={deleteButtonDisabled}>
						<FontAwesomeIcon icon={faTrash} /> Obriši
					</Button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	)
}

export default News
