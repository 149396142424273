import React, {useEffect, useState, useRef} from 'react'
import {
	Container,
	Row,
	Col,
	ListGroup,
	Jumbotron,
	Button,
	InputGroup,
	FormControl,
	Modal,
	Form,
	Badge
} from 'react-bootstrap'
import {
	useSelector
} from 'react-redux'
import {
	Redirect
} from 'react-router'
import AdminTopMenu from '../../components/AdminTopMenu'
import axios from 'axios'
import {
	FontAwesomeIcon
} from '@fortawesome/react-fontawesome'
import {
	faEnvelopeOpen,
	faPaperPlane,
	faTrash
} from '@fortawesome/free-solid-svg-icons'
import Loader from '../../components/Loader'
import moment from 'moment'
import 'moment/locale/sr'
import validate from 'validate.js'
import SimpleReactLightbox, {
	SRLWrapper
} from 'simple-react-lightbox'
import ErrorList from '../../components/ErrorList'

const Messages = () => {

	const messageEl = useRef(null)
	const auth = useSelector(state => state.user.auth)
	const user = useSelector(state => state.user.user)
	const emptyModalFormData = {
		emailId: '',
		message: ''
	}

	const [redirectToHome, setRedirectToHome] = useState(false)
	const [loadingAccounts, setLoadingAccounts] = useState(false)
	const [accounts, setAccounts] = useState([])
	const [selectedAccount, setSelectedAccount] = useState(null)
	const [messages, setMessages] = useState([])
	const [loadingMessages, setLoadingMessages] = useState(false)
	const [messageToSend, setMessageToSend] = useState(null)
	const [emailAddresses, setEmailAddresses] = useState([])
	const [loadingEmailAddresses, setLoadingEmailAddresses] = useState(false)
	const [modalFormData, setModalFormData] = useState(emptyModalFormData)
	const [disableSendButton, setDisableSendButton] = useState(false)
	const [modalErrors, setModalErrors] = useState(false)
	const [disableResponseButton, setDisableResponseButton] = useState(false)
	const [responseMessage, setResponseMessage] = useState('')
	const [messageToDelete, setMessageToDelete] = useState(null)
	const [disableDeleteButton, setDisableDeleteButton] = useState(false)

	const constraints = {
		emailId: {
			presence: {
				allowEmpty: false,
				message: 'Morate odabrati email.'
			}
		}
	}

	useEffect(() => {
		if (!auth) {
			setRedirectToHome(true)
		} else {
			getMessageAccounts()
		}
	}, [])

	const getMessageAccounts = () => {
		setLoadingAccounts(true)
		axios.get(`${process.env.REACT_APP_API_URL}/api/message-accounts`, {
			headers: {
				Authorization: `Bearer ${auth.access_token}`
			}
		})
			.then(response => {
				setLoadingAccounts(false)
				console.log('getMessageAccounts', response)
				setAccounts(response.data)
			})
			.catch(error => {
				setLoadingAccounts(false)
				console.log('getMessageAccounts error', error)
			})
	}

	const getMessagesByAccount = accountId => {
		setLoadingMessages(true)
		axios.get(`${process.env.REACT_APP_API_URL}/api/messages/${accountId}`, {
			headers: {
				Authorization: `Bearer ${auth.access_token}`
			}
		})
			.then(response => {
				setLoadingMessages(false)
				setMessages(response.data)
				messageEl.current.scrollIntoView({ behavior: 'smooth' })
				console.log('messages', response)
			})
			.catch(error => {
				setLoadingMessages(false)
				console.log('getMessagesByAccount error', error)
			})
	}

	const selectAccount = account => {
		getMessagesByAccount(account.id)
		if (account.has_new_messages) {
			const newAccount = {
				...account,
				has_new_messages: false
			}
			const newAccounts = accounts.map(item => item.id === newAccount.id ? newAccount : item)
			setAccounts(newAccounts)
			setSelectedAccount(newAccount)
			axios.post(`${process.env.REACT_APP_API_URL}/api/message-accounts/uypdate-messages/${newAccount.id}`, null, {
				headers: {
					Authorization: `Bearer ${auth.access_token}`
				}
			})
				.then(response => {

				})
				.catch(error => {

				})
		} else {
			setSelectedAccount(account)
		}
	}

	const manageShowMessageModal = message => {
		setMessageToSend(message)
		if (message && emailAddresses.length === 0) {
			getEmailAddresses()
		}
		if (!message) {
			setMessageToSend(null)
			setModalFormData({ ...emptyModalFormData })
			setModalErrors([])
		}
	}

	const getEmailAddresses = () => {
		setLoadingEmailAddresses(true)
		axios.get(`${process.env.REACT_APP_API_URL}/api/key-value/potisje_email`, {
			headers: {
				Authorization: `Bearer ${auth.access_token}`
			}
		})
			.then(response => {
				setLoadingEmailAddresses(false)
				setEmailAddresses(response.data)
			})
			.catch(error => {
				setLoadingEmailAddresses(false)
			})
	}

	const changeModalFormData = (name, value) => {
		setModalFormData({
			...modalFormData,
			[name]: value
		})
	}

	const sendMessage = () => {
		let messages = validate(
			modalFormData,
			constraints,
			{
				format: 'flat',
				fullMessages: false
			}
		)
		if (messages) {
			setModalErrors(messages)
		} else {
			setModalErrors([])
			setDisableSendButton(true)
			axios.post(`${process.env.REACT_APP_API_URL}/api/forward-email`, {
				email_id: modalFormData.emailId,
				message_id: messageToSend.id,
				message: modalFormData.message
			}, {
				headers: {
					Authorization: `Bearer ${auth.access_token}`
				}
			})
				.then(response => {
					setDisableSendButton(false)
					manageShowMessageModal(null)
					console.log('sendMessage', response)
				})
				.catch(error => {
					setDisableSendButton(false)
				})
		}
	}

	const sendResponse = () => {
		if (responseMessage && selectedAccount) {
			setDisableResponseButton(true)
			axios.post(`${process.env.REACT_APP_API_URL}/api/messages/response`, {
				message_account_id: selectedAccount.id,
				message: responseMessage
			}, {
				headers: {
					Authorization: `Bearer ${auth.access_token}`
				}
			})
				.then(response => {
					setDisableResponseButton(false)
					setResponseMessage('')
					setMessages([ ...messages, response.data ])
					messageEl.current.scrollIntoView({ behavior: 'smooth' })
				})
				.catch(error => {
					setDisableResponseButton(false)
					console.log('sendResponse error', error)
				})
		}
	}

	const manageDeleteMessageModal = message => {
		setMessageToDelete(message)
		if (!message) {
			setMessageToDelete(null)
			setModalErrors([])
		}
	}

	const deleteMessage = () => {
		if (messageToDelete) {
			setDisableDeleteButton(true)
			axios.delete(`${process.env.REACT_APP_API_URL}/api/messages/${messageToDelete.id}`, {
				headers: {
					Authorization: `Bearer ${auth.access_token}`
				}
			})
				.then(response => {
					const newMessages = [ ...messages ]
					const index = newMessages.findIndex(item => item.id === messageToDelete.id)
					newMessages.splice(index, 1)
					setMessages([ ...newMessages ])
					setDisableDeleteButton(false)
					manageDeleteMessageModal(null)
				})
				.catch(error => {
					setDisableDeleteButton(false)
					console.log('deleteMessage error', error)
				})
		}
	}

	return (
		<React.Fragment>
			{
				redirectToHome &&
				<Redirect to="/" />
			}
			<AdminTopMenu page="messages" />
			<div style={{ height: 'calc(100vh - 60px)' }}>
				<Container className="h-100">
					<Row className="h-100">
						<Col lg={3} className="pt-2">
							{/*<h3>Korisnici</h3>*/}
							{
								loadingAccounts ?
									<Loader />
									:
									<div style={{ height: 500, overflowY: 'auto' }}>
										<ListGroup>
											{
												accounts.map((item, index) => (
													<ListGroup.Item
														key={index}
														action
														onClick={() => selectAccount(item)}
														active={selectedAccount && selectedAccount.id === item.id}
														variant="light"
													>
														<div className="d-flex">
															<div className="mr-2">{item.full_name}</div>
															{
																item.has_new_messages &&
																<div
																	className="bg-primary"
																	style={{
																		width: 14,
																		height: 14,
																		borderRadius: 7
																	}}
																/>
															}
														</div>
													</ListGroup.Item>
												))
											}
										</ListGroup>
									</div>
							}
						</Col>
						<Col lg={9} className="d-flex flex-column">
							{/*<h3>Poruke</h3>*/}
							<div className="flex-grow-1">
								<Jumbotron className="m-0 p-0" style={{ height: 500 }}>
									{
										selectedAccount ?
											<div
												className="d-flex flex-column h-100"
												style={{ overflowY: 'scroll' }}
												id="messagesEl"
											>
												<div style={{ height: 'calc(100% - 80px)' }}>
													{
														loadingMessages ?
															<Loader />
															:
															<div>
																{
																	messages.map((item, index) => {
																		if (item.message_to) {
																			return (
																				<div key={index} className="p-2">
																					<p className="mb-0 text-secondary text-right">
																						<small>{moment(item.created_at).locale('sr').format('LLL')}</small>
																					</p>
																					<div className="d-flex justify-content-end">
																						<div className="mr-2">
																							{
																								user.roles.findIndex(item => item.role === 'delete_messages') > -1 &&
																								<Button
																									variant="danger"
																									onClick={() => manageDeleteMessageModal(item)}
																								>
																									<FontAwesomeIcon icon={faTrash} /> Obriši
																								</Button>
																							}
																						</div>
																						<div className="bg-success text-white p-3 rounded-lg flex-grow-1 mr-2" style={{ maxWidth: 250 }}>
																							<span style={{ whiteSpace: 'pre-line' }}>{item.message_content}</span>
																							{
																								item.message_images.length > 0 &&
																								<div>
																									<SimpleReactLightbox>
																										<SRLWrapper>
																											{
																												item.message_images.map((img, key) => (
																													<img src={`${process.env.REACT_APP_API_URL}/images/${img.image_uuid}`} style={{ width: 100, height: 100, objectFit: 'cover' }} className="mr-1"  key={key}/>
																												))
																											}
																										</SRLWrapper>
																									</SimpleReactLightbox>
																								</div>
																							}
																						</div>
																					</div>
																				</div>
																			)
																		} else {
																			return (
																				<div key={index} className="p-2">
																					<p className="mb-0 text-secondary">
																						<small>{moment(item.created_at).locale('sr').format('LLL')}</small>
																					</p>
																					<div className="d-flex">
																						<div className="bg-primary text-white p-3 rounded-lg flex-grow-1 mr-2" style={{ maxWidth: 250 }}>
																							<span style={{ whiteSpace: 'pre-line' }}>{item.message_content}</span>
																							{
																								item.message_images.length > 0 &&
																								<div>
																									<SimpleReactLightbox>
																										<SRLWrapper>
																											{
																												item.message_images.map((img, key) => (
																													<img src={`${process.env.REACT_APP_API_URL}/images/${img.image_uuid}`} style={{ width: 100, height: 100, objectFit: 'cover' }} className="mr-1"  key={key}/>
																												))
																											}
																										</SRLWrapper>
																									</SimpleReactLightbox>
																								</div>
																							}
																						</div>
																						<div className="d-flex flex-column">
																							<Button
																								variant="secondary"
																								onClick={() => manageShowMessageModal(item)}
																								className="mb-1"
																							>
																								<FontAwesomeIcon icon={faPaperPlane} /> Prosledi
																							</Button>
																							{
																								user.roles.findIndex(item => item.role === 'delete_messages') > -1 &&
																								<Button
																									variant="danger"
																									onClick={() => manageDeleteMessageModal(item)}
																								>
																									<FontAwesomeIcon icon={faTrash} /> Obriši
																								</Button>
																							}
																						</div>
																					</div>
																				</div>
																			)
																		}
																	})
																}
															</div>
													}
													<div ref={messageEl} className="test" />
												</div>
											</div>
											:
											<div className="d-flex flex-column align-items-center justify-content-center h-100">
												<FontAwesomeIcon icon={faEnvelopeOpen} size="2x" className="mb-3" />
												<p>Odaberite korisnika</p>
											</div>
									}
								</Jumbotron>
								{
									selectedAccount && !loadingMessages &&
									<div style={{ height: 80 }} className="d-flex justify-content-end">
										<InputGroup>
											<FormControl
												as="textarea"
												placeholder="Odgovor korisniku"
												aria-label="Odgovor korisniku"
												aria-describedby="basic-addon2"
												value={responseMessage}
												onChange={evt => setResponseMessage(evt.target.value)}
											/>
											<InputGroup.Append>
												<Button onClick={sendResponse} disabled={disableResponseButton}>Pošalji</Button>
											</InputGroup.Append>
										</InputGroup>
									</div>
								}
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<Modal show={!!messageToSend} onHide={() => manageShowMessageModal(null)}>
				<Modal.Header closeButton>
					<Modal.Title>Prosledi poruku</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						loadingEmailAddresses ?
							<Loader />
							:
							<React.Fragment>
								<p><strong>Sadržaj poruke</strong></p>
								<p>{messageToSend && messageToSend.message_content}</p>
								<hr />
								<Form>
									<ErrorList errors={modalErrors} />
									<Form.Group controlId="email">
										<Form.Label>Email adresa</Form.Label>
										<Form.Control
											as="select"
											name="emailId"
											value={modalFormData.emailId}
											onChange={evt => changeModalFormData(evt.target.name, evt.target.value)}
										>
											<option />
											{
												emailAddresses.map((item, index) => (
													<option value={item.id} key={index}>{item.value}</option>
												))
											}
										</Form.Control>
									</Form.Group>
									<Form.Group controlId="message">
										<Form.Label>Dodatna poruka</Form.Label>
										<Form.Control
											as="textarea"
											rows="3"
											name="message"
											value={modalFormData.message}
											onChange={evt => changeModalFormData(evt.target.name, evt.target.value)}
										/>
									</Form.Group>
								</Form>
							</React.Fragment>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => manageShowMessageModal(null)}>
						Odustani
					</Button>
					<Button variant="primary" disabled={disableSendButton} onClick={sendMessage}>
						Pošalji
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={!!messageToDelete} onHide={() => manageDeleteMessageModal(null)}>
				<Modal.Header closeButton>
					<Modal.Title>Obriši poruku</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Da li ste sigurni da želite da obrišete poruku?</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => manageDeleteMessageModal(null)}>
						Odustani
					</Button>
					<Button variant="danger" disabled={disableDeleteButton} onClick={deleteMessage}>
						Obriši
					</Button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	)
}

export default Messages
