import React, {
	useState,
	useEffect
} from 'react'
import {
	Container,
	Navbar,
	Nav
} from 'react-bootstrap'
import {
	Link
} from 'react-router-dom'
import {
	Redirect
} from 'react-router'
import {
	useDispatch,
	useSelector
} from 'react-redux'
import {
	setUserValue
} from '../../store/actions/user'
import {
	FontAwesomeIcon
} from '@fortawesome/react-fontawesome'
import {
	faSignOutAlt,
	faCommentAlt,
	faInfo,
	faNewspaper,
	faImages,
	faImage
} from '@fortawesome/free-solid-svg-icons'

const AdminTopMenu = props => {

	const dispatch = useDispatch()
	const user = useSelector(state => state.user.user)
	//const userRoles = useSelector(state => state.user.userRoles)

	const [redirectToHome, setRedirectToHome] = useState(false)

	const page = props.page ? props.page : ''

	/*useEffect(() => {
		if (userRoles.length === 0) {
			try {
				dispatch(setUserValue('userRoles', user.roles.map(role => role.role)))
			} catch (error) {}
		}
	}, [])*/

	const logout = () => {
		localStorage.removeItem('auth')
		dispatch(setUserValue('auth', null))
			.then(() => setRedirectToHome(true))
	}

	return (
		<React.Fragment>
			{
				redirectToHome &&
					<Redirect to="/" />
			}
			<Navbar bg="primary" variant="dark" expand="lg">
				<Container>
					<Navbar.Brand as={Link} to="/user">Potisje Admin</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="mr-auto">
							{
								user.roles.findIndex(item => item.role === 'messages') > -1 &&
								<Nav.Link as={Link} to="/user/messages" active={page === 'messages'}>
									<FontAwesomeIcon icon={faCommentAlt} /> Poruke
								</Nav.Link>
							}
							{
								user.roles.findIndex(item => item.role === 'notices') > -1 &&
								<Nav.Link as={Link} to="/user/notices" active={page === 'notices'}>
									<FontAwesomeIcon icon={faInfo} /> Obaveštenja
								</Nav.Link>
							}
							{
								user.roles.findIndex(item => item.role === 'news') > -1 &&
								<Nav.Link as={Link} to="/user/news" active={page === 'news'}>
									<FontAwesomeIcon icon={faNewspaper} /> Vesti
								</Nav.Link>
							}
							{
								user.roles.findIndex(item => item.role === 'galleries') > -1 &&
								<Nav.Link as={Link} to="/user/galleries" active={page === 'galleries'}>
									<FontAwesomeIcon icon={faImages} /> Galerije
								</Nav.Link>
							}
							{
								user.roles.findIndex(item => item.role === 'images') > -1 &&
								<Nav.Link href="#link">
									<FontAwesomeIcon icon={faImage} /> Slike
								</Nav.Link>
							}
							<Nav.Link onClick={logout}>
								<FontAwesomeIcon icon={faSignOutAlt} /> Odjavi se
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</React.Fragment>
	)
}

export default AdminTopMenu
