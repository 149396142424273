import {
  SET_USER_VALUE
} from '../actions/actionTypes.js'

const initialState = {
  auth: null,
	user: null,
	userRoles: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_VALUE:
    	console.log(action.payload.name, action.payload.value)
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    default:
      return state
  }
}

export default reducer
