import React, {useEffect, useState} from 'react'
import {
	Container
} from 'react-bootstrap'
import {
	useSelector
} from 'react-redux'
import {
	Redirect
} from 'react-router'
import AdminTopMenu from '../../components/AdminTopMenu'

const Galleries = () => {

	const auth = useSelector(state => state.user.auth)

	const [redirectToHome, setRedirectToHome] = useState(false)

	useEffect(() => {
		if (!auth) {
			setRedirectToHome(true)
		}
	}, [])

	return (
		<React.Fragment>
			{
				redirectToHome &&
				<Redirect to="/" />
			}
			<AdminTopMenu page="galleries" />
			<Container>
				<h2>Galerija</h2>
			</Container>
		</React.Fragment>
	)
}

export default Galleries
